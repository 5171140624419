import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LinkTree from "../components/linktree";


export default function Subscribe() {
  return (
    
    <div>
      <Layout>
      <SEO title="Start"/>
      <LinkTree /> 
      </Layout>
    </div>
  );
}

